import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GeneratePdf from "../../Utils/GeneratePdf";
import LetterHead from "../../components/LetterHead";

const IncomeStatement = () => {
	useEffect(() => {
		GetMonthTimestamps(Month);
	}, []);

	const GetMonthTimestamps = (monthString) => {
		const [year, month] = monthString.split("-").map(Number);

		// Get the first day of the month (month is 0-indexed)
		const firstDayOfMonth = new Date(year, month - 1, 1);

		// Get the first day of the next month
		const firstDayOfNextMonth = new Date(year, month, 1);

		// The end of the month is the last millisecond of the previous day of the next month
		const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);

		// Convert to Unix timestamps in milliseconds
		const startTimestamp = firstDayOfMonth.getTime(); // Start of the month
		const endTimestamp = lastDayOfMonth.getTime(); // End of the month (last millisecond)
		setStartLinker(startTimestamp);
		setEndLinker(endTimestamp);
		return { startTimestamp, endTimestamp };
	};

	const GetYearTimestamps = (yearString) => {
		const year = Number(yearString);

		// Get the first day of the year (January 1st, 00:00)
		const firstDayOfYear = new Date(year, 0, 1);

		// Get the first day of the next year
		const firstDayOfNextYear = new Date(year + 1, 0, 1);

		// The end of the year is the last millisecond of December 31st
		const lastDayOfYear = new Date(firstDayOfNextYear.getTime() - 1);

		// Convert to Unix timestamps in milliseconds
		const startTimestamp = firstDayOfYear.getTime(); // Start of the year
		const endTimestamp = lastDayOfYear.getTime(); // End of the year (last millisecond)

		setStartLinker(startTimestamp);
		setEndLinker(endTimestamp);

		return { startTimestamp, endTimestamp };
	};

	const GetCurrentMonth = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
		return `${year}-${month}`;
	};
	const [Month, setMonth] = useState(GetCurrentMonth());
	const CurrentYear = new Date().getFullYear();
	const [Year, setYear] = useState(CurrentYear);
	const Years = Array.from({ length: 100 }, (v, i) => CurrentYear - i);
	const [Summary, setSummary] = useState("monthly");
	const [StartLinker, setStartLinker] = useState(Date.now());
	const [EndLinker, setEndLinker] = useState(Date.now());
	const Expenses = useSelector((state) => state.expense.expenses).filter(
		(item) => parseInt(item.deleted) === 0
	);

	const GeneralExpenses = Expenses.filter(
		(item) =>
			item.linker >= StartLinker &&
			item.linker <= EndLinker &&
			item.catLinker !== "service-fee" &&
			item.catLinker !== "other"
	);

	const GroupFeeExpenses = Expenses.filter(
		(item) =>
			item.linker >= StartLinker &&
			item.linker <= EndLinker &&
			item.catLinker === "service-fee"
	);

	const Entrys = useSelector((state) => state.entry.entrys).filter(
		(entry) => parseInt(entry.deleted) === 0
	);

	const IncomeEntries = Entrys.filter(
		(item) =>
			item.linker >= StartLinker &&
			item.linker <= EndLinker &&
			(item.type === "loan-interest" || item.type === "fine")
	);

	const DividendEntries = Entrys.filter(
		(item) =>
			item.linker >= StartLinker &&
			item.linker <= EndLinker &&
			item.type === "dividend"
	);

	const PayrollCats = useSelector(
		(state) => state.payrollCat.payrollCats
	).filter((item) => parseInt(item.deleted) === 0);

	const PayrollItems = useSelector(
		(state) => state.payrollItem.payrollItems
	).filter((item) => parseInt(item.deleted) === 0);

	const PayrollEntries = useSelector(
		(state) => state.payrollEntry.payrollEntries
	).filter((item) => {
		return (
			parseInt(item.deleted) === 0 &&
			item.month >= new Date(StartLinker).toISOString().slice(0, 7) &&
			item.month <= new Date(EndLinker).toISOString().slice(0, 7)
		);
	});

	const FilteredPayrollEntries = PayrollEntries.filter((item) =>
		PayrollItems.some(
			(payrollItem) =>
				parseInt(payrollItem.linker) === parseInt(item.itemLinker) &&
				PayrollCats.some(
					(payrollCat) =>
						parseInt(payrollCat.linker) === parseInt(payrollItem.catLinker) &&
						["Income", "Allowance"].includes(payrollCat.type)
				)
		)
	);

	return (
		<div>
			<div className="row justify-content-center">
				<div className="col-md-6 col-lg-5 col-xl-4 card m-1" id="Profit&Loss">
					<LetterHead></LetterHead>
					<div className="d-flex justify-content-around card-header">
						<strong>P$L A/C </strong>{" "}
						<div>
							<select
								onChange={(e) => {
									setSummary(e.target.value);
									return e.target.value === "monthly"
										? GetMonthTimestamps(Month)
										: e.target.value === "annual"
										? GetYearTimestamps(Year)
										: null;
								}}
								value={Summary}
								className="rounded form-control"
							>
								<option value={"monthly"}>Monthly Sum</option>
								<option value={"annual"}>Annual Sum</option>
							</select>
						</div>
						{Summary === "monthly" ? (
							<div>
								<input
									type="month"
									value={Month}
									onClick={(e) => e.target.showPicker()}
									onChange={(e) => {
										setMonth(e.target.value);
										GetMonthTimestamps(e.target.value);
									}}
									className="rounded form-control"
								/>
							</div>
						) : Summary === "annual" ? (
							<div>
								<select
									className="rounded  form-control p-1"
									value={Year}
									onChange={(e) => {
										setYear(e.target.value);
										GetYearTimestamps(e.target.value);
									}}
								>
									{Years.map((year) => (
										<option key={year} value={year}>
											{year}
										</option>
									))}
								</select>
							</div>
						) : null}
					</div>
					<div className="card-body">
						<div>
							<p className="text-center h6 text-decoration-underline text-primary">
								For Interest As Income
							</p>
							<table className="table table-sm table-responsive table-striped">
								<tbody>
									<tr>
										<th>Interest Accrued</th>{" "}
										<td>{IncomeEntries.reduce((a, b) => +a + +b.amount, 0)}</td>
									</tr>
									<tr>
										<th>Expenses Incured</th>{" "}
										<td>{Expenses.reduce((a, b) => +a + +b.amount, 0)}</td>
									</tr>
									<tr>
										<th>Salaries Paid</th>{" "}
										<td>
											{FilteredPayrollEntries.reduce(
												(a, b) => +a + +b.amount,
												0
											)}
										</td>
									</tr>
									<tr>
										<th>Dividend Shared</th>{" "}
										<td>
											{DividendEntries.reduce((a, b) => +a + +b.amount, 0)}
										</td>
									</tr>
									<tr>
										<th>Net Profit</th>{" "}
										<td>
											{IncomeEntries.reduce((a, b) => +a + +b.amount, 0) -
												Expenses.reduce((a, b) => +a + +b.amount, 0) -
												DividendEntries.reduce((a, b) => +a + +b.amount, 0) -
												FilteredPayrollEntries.reduce(
													(a, b) => +a + +b.amount,
													0
												)}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<hr />
						<div>
							<p className="text-center h6 text-decoration-underline text-primary">
								For Management Fee As Income
							</p>
							<table className="table table-sm table-responsive table-striped">
								<tbody>
									<tr>
										<th>Management Fee</th>{" "}
										<td>
											{GroupFeeExpenses.reduce((a, b) => +a + +b.amount, 0)}
										</td>
									</tr>
									<tr>
										<th>Expenses Incured</th>{" "}
										<td>
											{GeneralExpenses.reduce((a, b) => +a + +b.amount, 0)}
										</td>
									</tr>
									<tr>
										<th>Salaries Paid</th>{" "}
										<td>
											{FilteredPayrollEntries.reduce(
												(a, b) => +a + +b.amount,
												0
											)}
										</td>
									</tr>
									<tr>
										<th>Net Profit</th>{" "}
										<td>
											{GroupFeeExpenses.reduce((a, b) => +a + +b.amount, 0) -
												GeneralExpenses.reduce((a, b) => +a + +b.amount, 0) -
												FilteredPayrollEntries.reduce(
													(a, b) => +a + +b.amount,
													0
												)}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<GeneratePdf
				id={`Profit&Loss`}
				name={`${Summary === "monthly" ? Month : Year}-${Summary}-Profit&Loss`}
			>
				{" "}
			</GeneratePdf>
		</div>
	);
};

export default IncomeStatement;
