import React from "react";
import image from "../../assets/images/finance-management-tech-system.png";
import client from "../../assets/images/finance-management-tech-system-client.jpg";
import branch from "../../assets/images/finance-management-tech-system-branch.jpg";
import staff from "../../assets/images/finance-management-tech-system-staff.jpg";
import account from "../../assets/images/finance-management-tech-system-accounts.jpg";
import group from "../../assets/images/finance-management-tech-system-group.jpg";
import entries from "../../assets/images/finance-management-tech-system-entries.jpg";
import application from "../../assets/images/finance-management-tech-system-application.jpg";
import expenses from "../../assets/images/finance-management-tech-system-expenses.jpg";

import AuthModal from "../Auth/AuthModal";
import Header from "../../components/Header";
import PwaInstall from "../../Utils/PwaInstall";

const Home = () => {
	return (
		<div
			style={{
				minHeight: "100vh",
				minWidth: "100vw",

				backgroundImage: `url(${image})`,
				backgroundSize: "cover",
				backgroundRepeat: "repeat-y",
				backgroundPosition: "center",
			}}
		>
			<Header></Header>
			<div
				style={{
					marginTop: "40px",
					backgroundColor: "rgba(64, 99, 112, 0.5)",
					minHeight: "100vh",
					minWidth: "100vw",
				}}
			>
				<div style={{ minHeight: "10vh" }}>
					<p className="text-light text-center h5 bg-dark">
						Become A World Class Manager And Administrator
					</p>
				</div>

				<div
					style={{
						backgroundColor: "rgba(255, 255, 255)",
						borderRadius: "20%",
						padding: "2vh",
					}}
				>
					{" "}
					<p className="text-center h6">
						Eliminate Stress Of Tedious Sheets And Paper Work
					</p>{" "}
					<AuthModal></AuthModal>
					<div className="d-flex justify-content-around mt-1">
						<a
							download
							href="https://dl.dropbox.com/scl/fi/kur0uqzs3p73d4kbvrdsb/Finance-Techsystem-6.0.zip?rlkey=5fsniomq1owtbkxi08bz9mx9a&st=sz9btiwi&dl=0"
						>
							<button className="btn btn-secondary rounded-pill">
								Download Software (Offline & Online)
							</button>
						</a>
						<PwaInstall Title={"(Online Only)"}></PwaInstall>
					</div>
				</div>
				<div className="d-flex justify-content-center">
					{/*  <video
            style={{ width: "100%", height: "auto" }}
            controls
            autoPlay={false}
            loop
            muted
            poster="https://www.dropbox.com/scl/fi/my1uu9lwyxrnsbogxfrwh/fb-finance.jpg?rlkey=rlh8ky1fzwbtn98g933v4mo49&raw=1"
          >
            <source
              src="https://www.dropbox.com/scl/fi/106seo3eugmgvp10d7p44/SACCO-or-Microfinance-self-help-groups-savings-loan-credit-software-management-system.mp4?rlkey=x5y6c40y1ma1yqc8cpqnrha5o&raw=1"
              type="video/mp4"
            ></source>
          </video>*/}
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/H4zUoacM3dk?si=6PIXsxVifwY-zc8v"
						title="SACCO OR MICROFINANACE SOFTWARE MANAGEMENT SYSTENM"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				</div>
				<div className="row bg-transparent d-flex justify-content-around m-2">
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Staff Access</p>
							</div>
							<img
								className="card-img-top"
								src={staff}
								alt="finance-management-tech-system-staff"
							/>
							<div className="mx-1">
								<p className="card-text">
									Improve institution connectivity by adding staffs and manage
									their access levels from Admin and Staffs.
								</p>
								<p className="card-text">
									Staffs Can use the email you register them with to access
									their portal
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Client Records</p>
							</div>
							<img
								className="card-img-top"
								src={client}
								alt="finance-management-tech-system-client"
							/>
							<div className="mx-1 pt-2">
								<p className="card-text"></p>
								<p className="card-text"></p>
								<p className="card-text">
									Manage client records with ease helps you to add and filter
									clients by group and be able to view all clients information
									from one place
								</p>
								<p className="card-text"></p>
								<p className="card-text"></p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Branches</p>
							</div>
							<img
								className="card-img-top"
								src={branch}
								alt="finance-management-tech-system-branch"
							/>
							<p className="card-text"></p>
							<p className="card-text"></p>
							<div className="mx-1">
								<p className="card-text">
									Enjoy maximum flexibility by adding your institution branches
									helping you to filter staffs and transactions according to
									branch
								</p>
								<p className="card-text"></p>
								<p className="card-text"></p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Savings And Credit Accounts</p>
							</div>
							<img
								className="card-img-top"
								src={account}
								alt="finance-management-tech-system-accounts"
							/>
							<div className="mx-1 py-4">
								<p className="card-text pb-4">
									Manage both savings and credit accounts by adding as many
									types of accounts to fit your needs
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Clients Groups</p>
							</div>
							<img
								className="card-img-top"
								src={group}
								alt="finance-management-tech-system-group"
							/>
							<div className="mx-1 py-5">
								<p className="card-text">
									You can add groups to segratee your clients either by
									geoghraphical location or common interests
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Transactions Summary</p>
							</div>
							<img
								className="card-img-top"
								src={entries}
								alt="finance-management-tech-system-entries"
							/>
							<div className="mx-1">
								<ul>
									<li>Get detailed and summation of all transactions</li>
									<li>View Accounts totals</li>{" "}
									<li>
										Generate a client account summary and downloadable as pdf
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Savings And Credit Applications</p>
							</div>
							<img
								className="card-img-top"
								src={application}
								alt="finance-management-tech-system-application"
							/>
							<div className="mx-1 py-4">
								<p className="card-text">
									Manage clients application from review to settled status
								</p>
								<p className="card-text">
									View total amount of pending Applications
								</p>
							</div>
						</div>
					</div>

					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Expenses</p>
							</div>
							<img
								className="card-img-top"
								src={expenses}
								alt="finance-management-tech-system-expenses"
							/>
							<div className="mx-1 ">
								<p className="card-text">
									Manage expenses like a proffessional
								</p>
								<p className="card-text">
									View when and who incurred a specific expense and total
									summary by month or year
								</p>
								<p className="card-text">
									Add expense categories to see which expenses are highly
									incurred
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
