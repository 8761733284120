import React, { useState, useEffect } from "react";
import ClientEntry from "../Members/ClientEntry";
import { useDispatch, useSelector } from "react-redux";
import NoEntry from "./NoEntries";
import EntryDisp from "./EntryDisp";
import { FetchEntrys } from "../../Network/EntryApi";
import { PlainList } from "flatlist-react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBarLeft } from "react-bootstrap-icons";
import GeneratePdf from "../../Utils/GeneratePdf";
import LetterHead from "../../components/LetterHead";

const ClientEntries = () => {
	useEffect(() => {
		FetchEntrys(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	//redux dispatch
	const dispatch = useDispatch();
	const params = useParams();
	const Navigate = useNavigate();

	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Creds = useSelector((state) => state.cred.creds).filter(
		(cred) => parseInt(cred.deleted) === 0
	);
	const Accounts = useSelector((state) => state.account.accounts).filter(
		(account) => parseInt(account.deleted) === 0
	);
	const Branches = useSelector((state) => state.branch.branchs).filter(
		(branch) => parseInt(branch.deleted) === 0
	);
	const Entries = useSelector((state) => state.entry.entrys)
		.filter(
			(entry) =>
				parseInt(entry.deleted) === 0 &&
				parseInt(entry.clientLinker) === parseInt(params.clientLinker) &&
				parseInt(entry.accountLinker) === parseInt(params.accountLinker)
		)
		.sort((a, b) => parseInt(a.linker) - parseInt(b.linker));
	const [EntryModal, setEntryModal] = useState(false);
	const [Entry, setEntry] = useState({ name: "" });
	const [Client, setClient] = useState({ name: "" });
	const [ActiveAc, setActiveAc] = useState({ name: "" });
	const [Type, setType] = useState("add");

	const ClientUser = () => {
		let cred = Creds.find(
			(cred) => parseInt(cred.linker) == parseInt(params.clientLinker)
		);

		return cred || { firstname: "unknown" };
	};

	const Account = () => {
		let account = Accounts.find(
			(account) => parseInt(account.linker) === parseInt(params.accountLinker)
		);

		return account || { name: "unknown" };
	};

	return (
		<div>
			<div id="client-entries">
				<div className="d-flex justify-content-around">
					<div>
						{" "}
						<button
							className="btn btn-sm btn-outline-secondary"
							onClick={() => Navigate(-1)}
						>
							<ArrowBarLeft></ArrowBarLeft> Go Back
						</button>
					</div>
					<div>
						<LetterHead></LetterHead>{" "}
						<p className="text-capitalize text-center m-0 p-0 h5">
							{ClientUser().clientReg} {ClientUser().firstname}{" "}
							{ClientUser().lastname} {ClientUser().surname}
						</p>
						<p className="text-capitalize text-center m-0 p-0 h6">
							{Account().name}
						</p>
					</div>
				</div>
				<table className="table table-sm table-bordered">
					<thead className="thead-dark">
						<tr>
							<th scope="colum">Mode</th>
							<th>Ac</th>
							<th scope="column">Type</th>
							{Branches.length > 0 ? <th scope="column">Branch</th> : null}
							<th>Staff</th>

							<th scope="column">Date</th>
							<th scope="column">Details</th>
							<th scope="column">Amount</th>
							<th scope="column">Total</th>
						</tr>
					</thead>
					{Entries.length > 0 ? (
						<tbody>
							<PlainList
								list={Entries.map((entry, index) => {
									const cumulativeTotal = Entries.slice(0, index + 1).reduce(
										(sum, currItem) =>
											currItem.type === "withdrawal" ||
											currItem.type === "fine" ||
											currItem.type === "payment"
												? +sum - +currItem.amount
												: +sum + +currItem.amount,
										0
									);

									return { ...entry, total: cumulativeTotal };
								}).sort((a, b) => parseInt(b.linker) - parseInt(a.linker))}
								renderOnScroll
								renderItem={(entry) => (
									<EntryDisp
										setShowModal={setEntryModal}
										entry={entry}
										setEntry={setEntry}
										setClient={setClient}
										setAccount={setActiveAc}
										setType={setType}
										key={entry.linker}
									></EntryDisp>
								)}
							/>
						</tbody>
					) : (
						<NoEntry></NoEntry>
					)}
				</table>
			</div>
			<GeneratePdf id={"client-entries"} name={"client-entries"}></GeneratePdf>
			<ClientEntry
				entry={Entry}
				setEntry={setEntry}
				ShowModal={EntryModal}
				setShowModal={setEntryModal}
				account={ActiveAc}
				type={Type}
				client={Client}
			></ClientEntry>
		</div>
	);
};

export default ClientEntries;
