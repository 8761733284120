import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RegisterLiveInst } from "../../Network/InstApi";
import { BuildingAdd } from "react-bootstrap-icons";
import { CountryCities } from "../../Utils/CountryCities";
import { ApiUrl } from "../../Network/Urls";

const RegisterInst = () => {
	useEffect(() => {
		if (Intl) {
			let TzArr = Intl.DateTimeFormat().resolvedOptions().timeZone.split("/");
			let userCity = TzArr[TzArr.length - 1];
			let userCountry = CountryCities[userCity];
			setCountry(userCountry);
		}
	}, []);

	const fileInputRef = useRef(null);
	const [ShowModal, setShowModal] = useState(false);
	const [Name, setName] = useState("");
	const [Email, setEmail] = useState("");
	const [Contact, setContact] = useState("");
	const [Logo, setLogo] = useState("");
	const [Motto, setMotto] = useState("");
	const [Country, setCountry] = useState("");
	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const [Loading, setLoading] = useState(false);

	//redux dispatch
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const HandleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		let clientId = `${parseInt(Date.now() / 1000)}`;
		let linker = `${Date.now()}`;

		await RegisterLiveInst(
			{
				name: Name,
				linker,
				motto: Motto,
				email: Email,
				logo: Logo,
				contact: Contact,
				trace: linker,
				deleted: 0,
				live: 1,
				clientId,
				userLinker: User.linker,
				sub: Country,
			},
			User,
			dispatch
		);
		setLoading(false);
		setShowModal(false);
		navigate(`/${Name}`);
		setName("");
	};

	const ConvertImage = () => {
		const fileInput = document.getElementById("fileInput");
		const file = fileInput.files[0];

		const reader = new FileReader();
		reader.onload = function (e) {
			const base64Data = e.target.result;

			let image = new Image();
			image.src = base64Data;

			image.onload = () => {
				let canvas = document.createElement("canvas");
				let ctx = canvas.getContext("2d");
				let MAX_SIZE = 200 * 1024;
				let width = image.width;
				let height = image.height;
				let ratio = 1;

				if (file.size > MAX_SIZE) {
					ratio = Math.sqrt(MAX_SIZE / file.size);
					width *= ratio;
					height *= ratio;
				}
				canvas.width = width;
				canvas.height = height;

				ctx.drawImage(image, 0, 0, width, height);

				let MinBase64 = canvas.toDataURL("image/jpeg", 0.7);
				setLogo(MinBase64);
			};
		};
		reader.readAsDataURL(file);
	};

	return (
		<div className="col-6 col-md-4 col-lg-3">
			<div className="dashboard py-2" onClick={() => setShowModal(true)}>
				<div className="d-flex justify-content-center">
					<BuildingAdd className="dash-icon"></BuildingAdd>
				</div>

				<p className="text-center dash-text text-wrap">Register New Business</p>
			</div>

			<Modal
				show={ShowModal}
				onHide={() => setShowModal(false)}
				backdrop="static"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Register New Business</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={HandleSubmit}>
						<input
							type="file"
							accept="image/*"
							onChange={() => ConvertImage()}
							style={{ display: "none", width: "0px", height: "0px" }}
							ref={fileInputRef}
							id="fileInput"
						/>
						<div
							className="form-group d-flex justify-content-around"
							onClick={() => {
								if (fileInputRef.current) {
									fileInputRef.current.click();
								}
							}}
							style={{ cursor: "pointer" }}
						>
							<div>
								<button
									type="button"
									className="btn btn-sm btn-info rounded-pill"
								>
									Add Logo
								</button>
							</div>
							<img
								src={
									Logo?.length > 500 ? `${Logo}` : `${ApiUrl}/uploads/${Logo}`
								}
								alt="logo-select"
								className="img-thumbnail"
								style={{ height: 100, width: "auto" }}
							/>
						</div>

						<div className="form-group">
							<label className="mb-2">Institution Name:</label>
							<input
								className="rounded form-control"
								placeholder="enter name"
								value={Name}
								onChange={(e) => setName(e.target.value)}
								required
							/>
							<hr />
						</div>
						<div className="form-group">
							<label className="mb-2">Institution Email:</label>
							<input
								className="rounded form-control"
								placeholder="enter email"
								value={Email}
								onChange={(e) => setEmail(e.target.value)}
								required
								type="email"
							/>
							<hr />
						</div>
						<div className="form-group">
							<label className="mb-2">Institution Contact:</label>
							<input
								className="rounded form-control"
								placeholder="enter contact"
								value={Contact}
								onChange={(e) => setContact(e.target.value)}
								required
							/>
							<hr />
						</div>
						<div className="form-group">
							<label className="mb-2">Institution Motto:</label>
							<input
								className="rounded form-control"
								placeholder="enter motto"
								value={Motto}
								onChange={(e) => setMotto(e.target.value)}
								required
							/>
							<hr />
						</div>
						<div className="d-flex justify-content-around mb-2">
							{Loading ? (
								<span className="spinner-border text-primary"></span>
							) : (
								<Button variant="primary" type="submit">
									Submit
								</Button>
							)}
							<Button
								variant="secondary"
								type="button"
								onClick={() => setShowModal(false)}
							>
								Cancel
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default RegisterInst;
